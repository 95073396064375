import React, { useState } from "react";
import firebase from "../../../firebase/firebase";
import {
    TextField,
    Button,
    Card,
    CardContent,
    Grid,
    CardHeader,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogActions,
    FormControl
} from "@material-ui/core";

export default function(props) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    function submitForm(e) {
        console.log("submitting");
        e.preventDefault();
        setLoading(true);
        firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(result => {
                setLoading(false);
            })
            .catch(err => {
                setError(err.message);
                setLoading(false);
            });
    }
    return (
        <Grid
            container
            alignItems="center"
            alignContent="center"
            justify="center"
            spacing={2}
            style={{ marginTop: "10px" }}
        >
            <Grid item xs={12} md={6} lg={6}>
                <Card align="center">
                    <CardHeader title="Sign In" />
                    <CardContent>
                        <form onSubmit={submitForm}>
                            <Grid
                                container
                                justify="center"
                                direction="column"
                                alignItems="center"
                                spacing={2}
                            >
                                <Grid item>
                                    <FormControl>
                                        <TextField
                                            type="text"
                                            value={email}
                                            onChange={e => {
                                                setEmail(e.target.value);
                                            }}
                                            label="email"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl>
                                        <TextField
                                            type="password"
                                            value={password}
                                            onChange={e => {
                                                setPassword(e.target.value);
                                            }}
                                            label="password"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            type="submit"
                                        >
                                            Sign In
                                        </Button>
                                    </FormControl>
                                    {loading && <CircularProgress size={10} />}
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                    <Dialog
                        open={Boolean(error)}
                        onClose={() => {
                            setError(null);
                        }}
                    >
                        <DialogContent>{error}</DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                variant="contained"
                                onClick={() => {
                                    setError(null);
                                }}
                            >
                                ok
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Card>
            </Grid>
        </Grid>
    );
}
