import React from "react";
import { AppBar, Toolbar, Button, IconButton } from "@material-ui/core";
import HamburgerMenu from "./HamburgerMenu";
import DisplayButton from "./DisplayButton";
import VolumeControl from "./VolumeControl";
import SupportButton from "./SupportButton";
import PortalButton from "./PortalButton";

export default function(props) {
    return (
        <AppBar position="static" style={{ width: "100%", margin: 0 }}>
            <Toolbar variant="dense" disableGutters style={{ minHeight: 0 }}>
                <HamburgerMenu />
                {/* <DisplayButton></DisplayButton>
                <VolumeControl></VolumeControl>
                <SupportButton></SupportButton>
                <PortalButton></PortalButton> */}
            </Toolbar>
        </AppBar>
    );
}
